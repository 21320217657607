<template>
    <div>
        <page-header
            :title="$t('pages.taxDocuments.title')"
            :show-back="true"
            @onClose="returnToRoot"
        />
        <loading-indicator
            v-if="isLoading"
            :title="$t('global.loadingMessage.loading')"
        />
        <error-view
            v-else-if="errorText"
            image-path-in-assets="images/pages/card/TaxDocumentError.svg"
            :show-title="false"
            :description="errorText"
            :image-width="112"
        />

        <div
            v-else
            class="container"
        >
            <card-block
                v-if="taxDocuments.length > 0"
                class="mb-2"
            >
                <div
                    v-for="(taxDocument, index) in taxDocuments"
                    :key="taxDocument.id"
                    @click="handleClick(taxDocument)"
                    @keydown.enter="handleClick(taxDocument)"
                >
                    <list-row
                        :title="getTaxDocumentName(taxDocument.year)"
                        :show-arrow="true"
                        :submitting="downloadingTaxDocument"
                        :ref="`tax_document_list_item_${index}`"
                        row-icon="images/pages/card/DocumentMoney.svg"
                        class="mx-0 px-0"
                    />
                </div>
            </card-block>
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import LoadingIndicator from '@/components/LoadingIndicator'
    import ErrorView from '@/components/ErrorView'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { mapGetters } from 'vuex'
    import CardBlock from '@/components/CardBlock'
    import ListRow from '@/components/ListRow'
    import { logger } from '@/utils/logger'
    import { isSafari } from '@/utils/parseUserAgents'
    import { navigation } from '@/mixins/navigation'
    import { getLegalDocumentDownload } from '@/services/api'
    import { i18n } from '@/utils/i18n'

    export default {
        name: 'TaxDocuments',
        components: {
            PageHeader,
            'loading-indicator': LoadingIndicator,
            ErrorView,
            CardBlock,
            ListRow,
        },
        data: function () {
            return {
                errorText: '',
                isLoading: false,
                taxDocumentToLoadIndex: null,
                docType: 'Irs1098',
                downloadingTaxDocument: false,
            }
        },
        mounted: async function () {
            this.$logEvent('view_tax_documents')
            if (this.isWebView) {
                this.errorText = i18n.t('pages.taxDocuments.webViewError')
            } else {
                await this.loadTaxDocuments()
                if (this.taxDocuments.length === 0) {
                    this.errorText = i18n.t('pages.taxDocuments.noTaxDocuments')
                }
            }
        },
        computed: {
            ...mapGetters(['taxDocuments', 'isWebView']),
        },
        mixins: [navigation],
        methods: {
            getTaxDocumentName: function (year) {
                return `Form 1098 (${year})`
            },
            openRedirectLink: function () {
                const baseUrl = 'my.aven.com/taxDocuments'
                let url = 'https://'
                if (process.env.VUE_APP_NODE_ENV === 'development') {
                    url = 'http://localhost:8083/taxDocuments'
                } else if (process.env.VUE_APP_NODE_ENV === 'production') {
                    url = `${url}${baseUrl}`
                } else {
                    url = `${url}${process.env.VUE_APP_NODE_ENV}.${baseUrl}`
                }

                logger.info(`Redirect URL: ${url}`)

                window.open(url, '_blank')
            },
            loadTaxDocuments: async function () {
                if (this.taxDocuments.length === 0) {
                    try {
                        await this.$store.dispatch('getTaxDocuments')
                    } catch (error) {
                        ApiErrorHandler(error)
                    }
                }
                logger.info(`${JSON.stringify(this.taxDocuments)}`)
            },
            handleClick: async function (selectedTaxDocument) {
                if (this.isWebView) {
                    this.errorText = i18n.t('pages.taxDocuments.webViewError')
                } else {
                    this.$logEvent('click_to_view_download_tax_document')
                    logger.info(`browser, download tax document`)
                    this.downloadingTaxDocument = true
                    const response = await getLegalDocumentDownload(this.docType, selectedTaxDocument.year)
                    logger.info(`browser, download tax document completed`)
                    const file = new Blob([response.data], { type: 'application/pdf' })
                    const fileURL = URL.createObjectURL(file)
                    if (isSafari()) {
                        window.location.href = fileURL
                    } else {
                        window.open(fileURL, '_blank')
                    }
                    this.downloadingTaxDocument = false
                }
            },
        },
    }
</script>

<style scoped></style>
